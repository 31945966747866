import { settings as settings } from '../environments/settings';
export const environment = {
  ...settings,
  template: 'pwa-1.0',
  theme: {
    name: 'poconos',
    appName: 'Poconos',
    productionHostName: 'poconos-trueomni.web.app',
    stagingHostName: 'staging-poconos.web.app',
    appCode: 'POCO'
  },
  cms: {
    domainId: 1074,
    accountId: 1074,
    channelId: 1372,
    deviceId: 1054,
    formId: 158,
    postalCode: '18360',
    bandwangosPageId: 3388,
    visitorCenterId: 18004,
    main_coordinates: {
      latitude: settings.site.latitude,
      longitude: settings.site.longitude,
    },
    navigation: {
      avatar_enabled: false,
      homePageId: 4424,
      itemsPerRowCategory: 2,
      footerPageId: 0
    }
  },
  regions:{
    enabled: true,
    regionsPageId: 6977
  },
  coreAuthLib: {
    base_url: 'https://usersapi.mobimanage.com',
    client_secret: 'NDHj6pVqoEGL4ABKBvxRYA',
  },
  firebase: {
    apiKey: "AIzaSyAAAMCxetcQqH4jL9PvsAyurukgVF1g9F8",
    authDomain: "king-drug.firebaseapp.com",
    databaseURL: "https://king-drug.firebaseio.com",
    projectId: "king-drug",
    storageBucket: "king-drug.appspot.com",
    messagingSenderId: "543629440411",
    appId: "1:543629440411:web:5770a939047f36e8bb5d3e"
  },
  messagesSDK: {
    client_id: 1634,
    client_secret: 'kzrEwm73X0Gm8-H9I7nQ5w',
    base_url: 'https://pushapi.mobimanage.com'
  },
  socialWall: {
    client_id: '2266',
    client_secret: 'uzf6u8KSB3rShbLLTqZUyrGKB4AJZ4vHB7Xfk2ksa12=',
    url: 'https://wall.crowdriff.com/live/24206c2e-3bba-407a-b649-8abd93f1b40b'
  },
  googleMaps: 'AIzaSyAb0SnmmZsewjJYWVDY5U3D8-oo1zKh-Jg',
  google: {
    client_id: '999537893149-buvugls54cbcj0lfpodt81ba6h311iua.apps.googleusercontent.com',
  },
  facebook: {
    app_id: '454932540645488',
  },
  batuta: {
    api_key: 'e8e98196453d6f0bd4ffd6553d56a829',
    base_url: 'http://battuta.medunes.net/api/'
  },
  tokenUniversal: {
    token: "DWuAh7qPqHqyp8la7Os4568OuHLu6dbpyvo11ZtsIfPvAnQgZOEPQk9Ssqdlb42VqVI"
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
