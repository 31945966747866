import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Listing } from '../data/Listing';
import { CMSEvent } from '../data/CMSEvent';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
  private KEYS = {
    listingItem: 'favorite_listing_',
    eventItem: 'favorite_event_',
  };

  constructor(
    private storage: Storage,
  ) { }

  isFavoriteListing(listingId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storage.get(`${this.KEYS.listingItem}${listingId}`).then(value => {
        resolve(value !== null);
      }).catch(err => reject(false));
    });
  }

  favoriteListing(listing: Listing): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storage.set(`${this.KEYS.listingItem}${listing.ListingID}`, JSON.stringify(listing)).then(value => {
        resolve(true);
      }).catch(err => reject(false));
    });
  }

  unfavoriteListing(listingId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storage.remove(`${this.KEYS.listingItem}${listingId}`).then(value => {
        resolve(value === 'true');
      }).catch(err => reject(false));
    });
  }

  isFavoriteEvent(eventId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storage.get(`${this.KEYS.eventItem}${eventId}`).then(value => {
        resolve(value !== null);
      }).catch(err => reject(false));
    });
  }

  favoriteEvent(event: CMSEvent): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storage.set(`${this.KEYS.eventItem}${event.EventID}`, JSON.stringify(event)).then(value => {
        resolve(true);
      }).catch(err => reject(false));
    });
  }

  unfavoriteEvent(eventId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storage.remove(`${this.KEYS.eventItem}${eventId}`).then(value => {
        resolve(value === 'true');
      }).catch(err => reject(false));
    });
  }

  isFavorite(listing?: Listing, event?: CMSEvent): Promise<boolean> {
    if (listing) {
      return this.isFavoriteListing(listing.ListingID);
    } else if (event) {
      return this.isFavoriteEvent(event.EventID);
    } else {
      //console.log('>>> listing & event are null');
      return new Promise<boolean>((resolve, reject) => resolve(false));
    }
  }

  favorite(listing?: Listing, event?: CMSEvent): Promise<boolean> {
    if (listing) {
      return this.favoriteListing(listing);
    } else if (event) {
      return this.favoriteEvent(event);
    } else {
      //console.log('>>> listing & event are null');
      return new Promise<boolean>((resolve, reject) => resolve(true));
    }
  }

  unfavorite(listingId?: number, eventId?: number): Promise<boolean> {
    if (listingId) {
      return this.unfavoriteListing(listingId);
    } else if (eventId) {
      return this.unfavoriteEvent(eventId);
    } else {
      //console.log('>>> listing id & event id are null');
      return new Promise<boolean>((resolve, reject) => resolve(false));
    }
  }

  async getFavoritesListings(): Promise<Listing[]> {
    return new Promise<Listing[]>(async (resolve, reject) => {
      const keys = await this.storage.keys();
      const whiteListedKeys: string[] = [];
      if (keys) {
        keys.forEach(item => {
          if (item.includes(this.KEYS.listingItem)) {
            whiteListedKeys.push(item);
          }
        });
      }

      const listings: Listing[] = [];
      if (whiteListedKeys.length === 0) {
        resolve(listings);
        return;
      }

      whiteListedKeys.forEach(async (key, index) => {
        // need to keep track of the index otherwise it will endup in a race condition issue
        const value = await this.storage.get(key);
        const listing: Listing = JSON.parse('' + value);
        listings.push(listing);

        if (whiteListedKeys.length - 1 === index) {
          resolve(listings);
        }
      });
    });
  }

  async getFavoritesEvents(): Promise<CMSEvent[]> {
    return new Promise<CMSEvent[]>(async (resolve, reject) => {
      const keys = await this.storage.keys();
      const whiteListedKeys: string[] = [];
      if (keys) {
        keys.forEach(item => {
          if (item.includes(this.KEYS.eventItem)) {
            whiteListedKeys.push(item);
          }
        });
      }

      const events: CMSEvent[] = [];
      if (whiteListedKeys.length === 0) {
        resolve(events);
        return;
      }

      whiteListedKeys.forEach(async (key, index) => {
        // need to keep track of the index otherwise it will endup in a race condition issue
        const value = await this.storage.get(key);
        const event: CMSEvent = JSON.parse('' + value);
        events.push(event);

        if (whiteListedKeys.length - 1 === index) {
          resolve(events);
        }
      });
    });
  }

}
